export function generateRandomString() {
  const randomPart = Math.random().toString(36).substring(2, 15);
  const timePart = new Date().getTime().toString(36);

  return randomPart + timePart;
}

export function isUrl(str) {
  const urlRegex = /^(http|https):\/\/[^ "]+$/;
  return urlRegex.test(str);
}

export function isValidEmail(email) {
  const emailRegex =
    /^[a-zA-Z0-9._%+-]+[a-zA-Z0-9]@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return emailRegex.test(email);
}

export async function copy(value) {
  const isClipboardApiSupported = navigator && "clipboard" in navigator;

  function legacyCopy(value) {
    const ta = document.createElement("textarea");
    ta.value = value ?? "";
    ta.style.position = "absolute";
    ta.style.opacity = "0";
    document.body.appendChild(ta);
    ta.select();
    document.execCommand("copy");
    ta.remove();
  }

  if (isClipboardApiSupported) {
    await navigator.clipboard.writeText(value);
  } else {
    legacyCopy(value);
  }
}