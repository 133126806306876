var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "pt-6 mr-4 ml-4", attrs: { "align-h": "center" } },
    [
      _c(
        "b-col",
        { attrs: { cols: "12", md: "9", lg: "8", xl: "8", xxl: "6" } },
        [
          _vm.data.id != undefined &&
          !_vm.hasSkippedUpload &&
          (_vm.data.verificationstatus === undefined ||
            (_vm.data.verificationstatus != undefined &&
              _vm.data.verificationstatus === "FAILED"))
            ? _c(
                "b-alert",
                { staticClass: "mb-5", attrs: { variant: "danger", show: "" } },
                [
                  _vm._v(" Important. You can’t edit your "),
                  _c("u", [_vm._v("Account Details")]),
                  _vm._v(" and "),
                  _c("u", [_vm._v("Identity Verification")]),
                  _vm._v(" once our doctor has verified your account "),
                ]
              )
            : _vm._e(),
          _vm.showDiscountMessage
            ? _c(
                "b-alert",
                {
                  staticClass: "mb-5",
                  staticStyle: {
                    background: "#1a6a72",
                    color: "#cdfcb1",
                    "border-color": "#1a6a72",
                  },
                  attrs: { show: "" },
                },
                [
                  _vm._v(
                    " Thank you! A 20% discount will be applied to your next shipment "
                  ),
                ]
              )
            : _vm._e(),
          _vm.editGroup
            ? _c(
                "b-card",
                { staticClass: "mb-5", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "static-margin", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-row",
                            { attrs: { "align-v": "center" } },
                            [
                              _c("b-col", [
                                _c(
                                  "h2",
                                  { staticClass: "mb-0 bold text-primary" },
                                  [_vm._v("Account Details")]
                                ),
                              ]),
                              _vm.data.id != undefined &&
                              (_vm.data.verificationstatus === undefined ||
                                (_vm.data.verificationstatus != undefined &&
                                  _vm.data.verificationstatus === "FAILED"))
                                ? _c(
                                    "b-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _vm.verificationEditUnlocked
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "btn-mini text-underline",
                                              attrs: { variant: "link" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.editAcountDetails = true
                                                },
                                              },
                                            },
                                            [_vm._v("Edit")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "static-margin",
                          attrs: { cols: "12", md: "6" },
                        },
                        [
                          _c("b-form-input", {
                            staticClass: "fs-mask",
                            attrs: {
                              type: "text",
                              value: _vm.firstName,
                              placeholder: "First Name",
                              readonly: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "static-margin",
                          attrs: { cols: "12", md: "6" },
                        },
                        [
                          _c("b-form-input", {
                            staticClass: "fs-mask",
                            attrs: {
                              type: "text",
                              value: _vm.lastName,
                              placeholder: "Last Name",
                              readonly: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "static-margin",
                          attrs: { cols: "12", md: "6" },
                        },
                        [
                          _c("b-form-input", {
                            staticClass: "fs-mask",
                            attrs: {
                              type: "text",
                              value: _vm.birthday,
                              placeholder: "Birthday",
                              readonly: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "static-margin",
                          attrs: { cols: "12", md: "6" },
                        },
                        [
                          _c("b-form-input", {
                            staticClass: "fs-mask",
                            attrs: {
                              type: "text",
                              value: _vm.phoneNo,
                              formatter: _vm.formatPhoneNumber,
                              placeholder: "Phone No.",
                              readonly: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.editGroup
            ? _c(
                "b-card",
                { staticClass: "mb-5", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "static-margin", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-row",
                            { attrs: { "align-v": "center" } },
                            [
                              _c("b-col", [
                                _c(
                                  "h2",
                                  { staticClass: "mb-0 bold text-primary" },
                                  [_vm._v("Shipping Address")]
                                ),
                              ]),
                              _c(
                                "b-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-mini text-underline",
                                      attrs: { variant: "link" },
                                      on: {
                                        click: function ($event) {
                                          _vm.editShippingInfo = true
                                        },
                                      },
                                    },
                                    [_vm._v("Edit")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "static-margin",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      value: _vm.address1,
                                      placeholder: "Address 1",
                                      readonly: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "static-margin",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      value: _vm.city,
                                      placeholder: "City",
                                      readonly: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "static-margin",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      value: _vm.statename,
                                      placeholder: "State",
                                      readonly: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "mb-4", attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "static-margin",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      value: _vm.address2,
                                      placeholder: "Address 2",
                                      readonly: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "static-margin",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      value: _vm.country,
                                      placeholder: "Country",
                                      readonly: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "static-margin",
                                  attrs: { cols: "12", md: "6" },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      value: _vm.zipCode,
                                      placeholder: "Zip Code",
                                      readonly: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.editGroup
            ? _c(
                "b-card",
                { staticClass: "mb-5", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "static-margin", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-row",
                            { attrs: { "align-v": "center" } },
                            [
                              _c("b-col", [
                                _c(
                                  "h2",
                                  { staticClass: "mb-0 bold text-primary" },
                                  [_vm._v("Billing Address")]
                                ),
                              ]),
                              _c(
                                "b-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _vm.sameAddress === "No"
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "btn-mini text-underline",
                                          attrs: { variant: "link" },
                                          on: {
                                            click: function ($event) {
                                              _vm.editBillingInfo = true
                                            },
                                          },
                                        },
                                        [_vm._v("Edit")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("b-form-group", {
                            attrs: { label: "Same as shipping address" },
                            nativeOn: {
                              change: function ($event) {
                                return _vm.updateBillingInfo.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ ariaDescribedby }) {
                                    return [
                                      _c(
                                        "b-form-radio",
                                        {
                                          staticClass: "shipping-radio",
                                          attrs: {
                                            "aria-describedby": ariaDescribedby,
                                            name: "shipping-adds",
                                            value: "Yes",
                                          },
                                          model: {
                                            value: _vm.sameAddress,
                                            callback: function ($$v) {
                                              _vm.sameAddress = $$v
                                            },
                                            expression: "sameAddress",
                                          },
                                        },
                                        [_vm._v("Yes")]
                                      ),
                                      _c(
                                        "b-form-radio",
                                        {
                                          staticClass: "shipping-radio",
                                          attrs: {
                                            "aria-describedby": ariaDescribedby,
                                            name: "shipping-adds",
                                            value: "No",
                                          },
                                          model: {
                                            value: _vm.sameAddress,
                                            callback: function ($$v) {
                                              _vm.sameAddress = $$v
                                            },
                                            expression: "sameAddress",
                                          },
                                        },
                                        [_vm._v("No")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1730022001
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm.sameAddress === "No"
                        ? [
                            _c(
                              "b-col",
                              { attrs: { cols: "12", md: "6" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "static-margin",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            value: _vm.billingAddress1,
                                            placeholder: "Address 1",
                                            readonly: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "static-margin",
                                        attrs: { cols: "12", md: "6" },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            value: _vm.billingCity,
                                            placeholder: "City",
                                            readonly: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "static-margin",
                                        attrs: { cols: "12", md: "6" },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            value: _vm.billingStateName,
                                            placeholder: "State",
                                            readonly: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "mb-4",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "static-margin",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            value: _vm.billingAddress2,
                                            placeholder: "Address 2",
                                            readonly: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "static-margin",
                                        attrs: { cols: "12", md: "6" },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            value: _vm.country,
                                            placeholder: "Country",
                                            readonly: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "static-margin",
                                        attrs: { cols: "12", md: "6" },
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            value: _vm.billingZipCode,
                                            placeholder: "Zip Code",
                                            readonly: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.editGroup
            ? _c(
                "b-card",
                { staticClass: "mb-5", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "static-margin", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-row",
                            { attrs: { "align-v": "center" } },
                            [
                              _c("b-col", [
                                _c(
                                  "h2",
                                  { staticClass: "mb-0 bold text-primary" },
                                  [
                                    _vm._v(" Billing Details "),
                                    _c(
                                      "small",
                                      {
                                        staticClass:
                                          "text-muted text-uppercase",
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "lock" },
                                        }),
                                        _vm._v("Secure"),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "b-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-mini text-underline",
                                      attrs: { variant: "link" },
                                      on: { click: _vm.updateBilling },
                                    },
                                    [_vm._v("Edit")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "static-margin",
                          attrs: { cols: "12", md: "6" },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c("b-input-group-text", [
                                    _c("img", {
                                      staticStyle: { margin: "0 5px" },
                                      attrs: {
                                        width: "40px",
                                        src: _vm.brand,
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                staticClass: "fs-mask",
                                attrs: {
                                  type: "text",
                                  value: _vm.lastFour,
                                  placeholder: "####",
                                  readonly: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "static-margin",
                          attrs: { cols: "12", md: "3" },
                        },
                        [
                          _c("b-form-input", {
                            staticClass: "fs-mask",
                            attrs: {
                              type: "text",
                              value: _vm.expiry,
                              placeholder: "MM/YY",
                              readonly: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "static-margin",
                          attrs: { cols: "12", md: "3" },
                        },
                        [
                          _c("b-form-input", {
                            staticClass: "fs-mask",
                            attrs: {
                              type: "text",
                              value: _vm.cvv,
                              placeholder: "CVV",
                              readonly: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "link" },
                              on: { click: _vm.getHSAReceipt },
                            },
                            [_vm._v("Download HSA Receipt")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.editGroup
            ? _c(
                "b-card",
                { staticClass: "mb-5", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "static-margin", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-row",
                            { attrs: { "align-v": "center" } },
                            [
                              _c("b-col", [
                                _c(
                                  "h2",
                                  { staticClass: "mb-0 bold text-primary" },
                                  [_vm._v("Security Settings")]
                                ),
                              ]),
                              _c(
                                "b-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-mini text-underline",
                                      attrs: { variant: "link" },
                                      on: {
                                        click: function ($event) {
                                          _vm.editSecurity = true
                                        },
                                      },
                                    },
                                    [_vm._v("Edit")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "static-margin",
                          attrs: { cols: "12", md: "6" },
                        },
                        [
                          _c("b-form-input", {
                            staticClass: "fs-mask",
                            attrs: {
                              type: "text",
                              value: _vm.email,
                              placeholder: "Email",
                              readonly: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "static-margin",
                          attrs: { cols: "12", md: "6" },
                        },
                        [
                          _c("b-form-input", {
                            staticClass: "fs-mask",
                            attrs: {
                              type: "password",
                              value: "password",
                              placeholder: "Password",
                              readonly: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.editGroup &&
          _vm.onBoardingStatus == "DONE" &&
          !_vm.data.hasOwnProperty("nonrxmedicationonly")
            ? _c(
                "b-card",
                { staticClass: "mb-5", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "static-margin", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-row",
                            { attrs: { "align-v": "center" } },
                            [
                              _c("b-col", [
                                _c(
                                  "h2",
                                  { staticClass: "mb-0 bold text-primary" },
                                  [_vm._v("Identity Verification")]
                                ),
                              ]),
                              _vm.data.id != undefined &&
                              (_vm.data.verificationstatus === undefined ||
                                (_vm.data.verificationstatus != undefined &&
                                  _vm.data.verificationstatus === "FAILED"))
                                ? _c(
                                    "b-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _vm.verificationEditUnlocked
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "btn-mini text-underline",
                                              attrs: { variant: "link" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.editPhoto = true
                                                },
                                              },
                                            },
                                            [_vm._v("Edit")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                        _c("small", { staticClass: "text-muted mb-3" }, [
                          _vm._v("Face Photo"),
                        ]),
                        _c("div", { staticClass: "photo-holder fs-exclude" }, [
                          _c(
                            "div",
                            {
                              staticClass: "photo",
                              style: {
                                backgroundImage:
                                  _vm.isPhotoTaken || _vm.showPhoto
                                    ? ""
                                    : "url(" +
                                      require("@/assets/images/photo-bg.svg") +
                                      ")",
                              },
                            },
                            [
                              _vm.isPhotoTaken
                                ? _c("img", { attrs: { src: _vm.picture } })
                                : _vm.showPhoto
                                ? _c("img", {
                                    attrs: { src: _vm.imagePreview },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _vm.photoVerificationFileType === "application/pdf"
                          ? _c(
                              "div",
                              { staticClass: "text-center" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticStyle: { "min-width": "200px" },
                                    attrs: {
                                      variant: "outline-info",
                                      href: _vm.photoURL,
                                      target: "_blank",
                                      pill: "",
                                    },
                                  },
                                  [_vm._v(" Open PDF ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                        _c("small", { staticClass: "text-muted mb-3" }, [
                          _vm._v("Identity Card"),
                        ]),
                        _c("div", { staticClass: "photo-holder fs-exclude" }, [
                          _c(
                            "div",
                            {
                              staticClass: "photo",
                              style: {
                                backgroundImage: !_vm.showId
                                  ? "url(" +
                                    require("@/assets/images/id-bg.svg") +
                                    ")"
                                  : "",
                                "background-size": "contain",
                                "background-color": "transparent",
                              },
                            },
                            [
                              _vm.showId
                                ? _c("img", {
                                    staticStyle: { "object-fit": "contain" },
                                    attrs: { src: _vm.idPreview },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _vm.idVerificationFileType === "application/pdf"
                          ? _c(
                              "div",
                              { staticClass: "text-center" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticStyle: { "min-width": "200px" },
                                    attrs: {
                                      variant: "outline-info",
                                      href: _vm.identificationURL,
                                      target: "_blank",
                                      pill: "",
                                    },
                                  },
                                  [_vm._v(" Open PDF ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.editGroup && !_vm.data.hasOwnProperty("nonrxmedicationonly")
            ? _c(
                "b-card",
                { staticClass: "mb-5", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "static-margin", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-row",
                            { attrs: { "align-v": "start" } },
                            [
                              _c("b-col", [
                                _c(
                                  "h2",
                                  { staticClass: "mb-3 bold text-primary" },
                                  [_vm._v("Pharmacy Information")]
                                ),
                                _c("p", { staticClass: "small-text" }, [
                                  _vm._v(
                                    " If you would like to change your pharmacy, please email our support team at "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "mailto:help@bywinona.com",
                                      },
                                    },
                                    [_vm._v("help@bywinona.com")]
                                  ),
                                ]),
                              ]),
                              _c(
                                "b-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _vm.canEditPharmacy
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "btn-mini text-underline",
                                          attrs: { variant: "link" },
                                          on: { click: _vm.showEditPharmacy },
                                        },
                                        [_vm._v("Edit")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "static-margin",
                          attrs: { cols: "12", md: "6" },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "text",
                              value: _vm.pharmacyName,
                              placeholder: "Pharmacy Name",
                              readonly: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "static-margin",
                          attrs: { cols: "12", md: "6" },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "text",
                              value: _vm.pharmacyAddress,
                              placeholder: "Pharmacy Address",
                              readonly: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.hasPrescription && _vm.editGroup
            ? _c(
                "b-row",
                { staticClass: "mb-4" },
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c("h2", { staticClass: "mb-3 bold text-primary" }, [
                      _vm._v("Cancel Subscription"),
                    ]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "mt-2" },
                    [
                      !this.fullycancelled
                        ? _c(
                            "b-button",
                            {
                              attrs: { pill: "", variant: "outline-primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push(
                                    "/cancel-subscription"
                                  )
                                },
                              },
                            },
                            [_vm._v("cancel subscription")]
                          )
                        : _c("p", [_vm._v("No active subscription available")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.editAcountDetails
            ? [
                _c("h2", { staticClass: "mb-6 bold text-primary" }, [
                  _vm._v("Account Details"),
                ]),
                _c(
                  "b-alert",
                  { attrs: { show: _vm.alert.show, variant: _vm.alert.type } },
                  [_vm._v(_vm._s(_vm.alert.message))]
                ),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c("label", { attrs: { for: "firstName" } }, [
                            _vm._v("First Name:"),
                          ]),
                          _c("b-form-input", {
                            staticClass: "pl-0 fs-mask",
                            attrs: { id: "firstName", type: "text" },
                            model: {
                              value: _vm.firstName,
                              callback: function ($$v) {
                                _vm.firstName = $$v
                              },
                              expression: "firstName",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "lastName" },
                            },
                            [_vm._v("Last Name:")]
                          ),
                          _c("b-form-input", {
                            staticClass: "pl-0 fs-mask",
                            attrs: { id: "lastName", type: "text" },
                            model: {
                              value: _vm.lastName,
                              callback: function ($$v) {
                                _vm.lastName = $$v
                              },
                              expression: "lastName",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "birthday" },
                            },
                            [_vm._v("Date of Birth:")]
                          ),
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##/##/####",
                                expression: "'##/##/####'",
                              },
                            ],
                            staticClass: "pl-0 fs-mask",
                            attrs: {
                              id: "birthday",
                              type: "text",
                              placeholder: "MM/DD/YYYY",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.birthday,
                              callback: function ($$v) {
                                _vm.birthday = $$v
                              },
                              expression: "birthday",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "phoneNumber" },
                            },
                            [_vm._v("Phone Number:")]
                          ),
                          _c("b-form-input", {
                            staticClass: "pl-0 fs-mask",
                            attrs: {
                              formatter: _vm.formatPhoneNumber,
                              id: "phoneNumber",
                              type: "text",
                            },
                            model: {
                              value: _vm.phoneNo,
                              callback: function ($$v) {
                                _vm.phoneNo = $$v
                              },
                              expression: "phoneNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { cols: "12", md: "6" } }),
                    _c(
                      "b-col",
                      { attrs: { cols: "6", md: "3" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              pill: "",
                              block: "",
                              variant: "outline-info",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.resetFields("user")
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "6", md: "3" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { pill: "", block: "", variant: "info" },
                            on: { click: _vm.updateUser },
                          },
                          [_vm._v("Update")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.editPharmacy
            ? [
                _c("h2", { staticClass: "mb-4 bold text-primary" }, [
                  _vm._v("Search your pharmacy"),
                ]),
                _c("p", [
                  _vm._v(
                    " If you pick a pharmacy outside of the Winona Pharmacy Network, and qualify for a prescription, we will not be able to guarantee the price of the medication and provide free monthly delivery. "
                  ),
                ]),
                _c(
                  "b-alert",
                  { attrs: { show: _vm.alert.show, variant: _vm.alert.type } },
                  [_vm._v(_vm._s(_vm.alert.message))]
                ),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { cols: "4" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "pharmacyName" },
                            },
                            [_vm._v("Pharmacy:")]
                          ),
                          _c("b-form-input", {
                            staticClass: "pl-0",
                            attrs: {
                              id: "pharmacyName",
                              type: "text",
                              placeholder: "Pharmacy Name",
                            },
                            model: {
                              value: _vm.pharmacyName,
                              callback: function ($$v) {
                                _vm.pharmacyName = $$v
                              },
                              expression: "pharmacyName",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "4" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "pharmacyState" },
                            },
                            [_vm._v("State:")]
                          ),
                          _c("v-select", {
                            attrs: {
                              id: "pharmacyState",
                              "append-to-body": "",
                              "calculate-position": _vm.withPopper,
                              label: "text",
                              options: _vm.stateoptions,
                              reduce: (stateoptions) => stateoptions.value,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "open-indicator",
                                  fn: function ({ attributes }) {
                                    return [
                                      _c(
                                        "b-icon",
                                        _vm._b(
                                          { attrs: { icon: "chevron-down" } },
                                          "b-icon",
                                          attributes,
                                          false
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              607575044
                            ),
                            model: {
                              value: _vm.pharmacyState,
                              callback: function ($$v) {
                                _vm.pharmacyState = $$v
                              },
                              expression: "pharmacyState",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "4" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "pharmacyCity" },
                            },
                            [_vm._v("City:")]
                          ),
                          _c("b-form-input", {
                            attrs: { name: "city", placeholder: "City" },
                            model: {
                              value: _vm.pharmacyCity,
                              callback: function ($$v) {
                                _vm.pharmacyCity = $$v
                              },
                              expression: "pharmacyCity",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "b-col",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "my-3",
                            attrs: { variant: "info", pill: "" },
                            on: { click: _vm.search },
                          },
                          [_vm._v("Search for a Pharmacy")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      {
                        staticStyle: {
                          "max-height": "250px",
                          overflow: "auto",
                        },
                        attrs: { cols: "12" },
                      },
                      [
                        _c(
                          "div",
                          { attrs: { id: "pharmItems" } },
                          _vm._l(_vm.searchedPharmacies, function (list) {
                            return _c("w-checkbox", {
                              key: list.PharmacyId,
                              attrs: {
                                type: "radio",
                                id: list.PharmacyId.toString(),
                                value: list,
                                label: list.StoreName + " - " + list.City,
                                description: `${list.Address1} ${list.Address2} ${list.City} ${list.State} ${list.ZipCode}`,
                              },
                              model: {
                                value: _vm.selectedPharmacy,
                                callback: function ($$v) {
                                  _vm.selectedPharmacy = $$v
                                },
                                expression: "selectedPharmacy",
                              },
                            })
                          }),
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-3 mb-3" },
                  [
                    _c("b-col", { attrs: { cols: "12", md: "6" } }),
                    _c(
                      "b-col",
                      { attrs: { cols: "6", md: "3" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              pill: "",
                              block: "",
                              variant: "outline-info",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.resetFields("user")
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "6", md: "3" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { pill: "", block: "", variant: "info" },
                            on: { click: _vm.updatePharmacy },
                          },
                          [_vm._v("Update")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editShippingInfo,
                  expression: "editShippingInfo",
                },
              ],
            },
            [
              _c("h2", { staticClass: "mb-6 bold text-primary" }, [
                _vm._v("Shipping Address"),
              ]),
              _c(
                "b-alert",
                { attrs: { show: _vm.alert.show, variant: _vm.alert.type } },
                [_vm._v(_vm._s(_vm.alert.message))]
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "mb-3", attrs: { role: "group" } },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "d-letter-spacing",
                                    attrs: { for: "address" },
                                  },
                                  [_vm._v("Street Address:")]
                                ),
                                _c("vue-google-autocomplete", {
                                  ref: "streetAddressShipping",
                                  staticClass: "fs-mask onboarding-input",
                                  attrs: {
                                    id: "map1",
                                    classname: "form-control",
                                    placeholder: " ",
                                    country: ["us", "pr"],
                                  },
                                  on: {
                                    placechanged: _vm.getShippingAddressData,
                                    inputChange:
                                      _vm.onStreetAddressShippingInputChange,
                                    change: _vm.onStreetAddressShippingChange,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "mb-3", attrs: { role: "group" } },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "d-letter-spacing",
                                    attrs: { for: "address2" },
                                  },
                                  [_vm._v("Apartment/Suite:")]
                                ),
                                _c("b-form-input", {
                                  staticClass: "pl-0",
                                  attrs: {
                                    id: "address2",
                                    type: "text",
                                    formatter: _vm.removeAccents,
                                  },
                                  model: {
                                    value: _vm.address2,
                                    callback: function ($$v) {
                                      _vm.address2 = $$v
                                    },
                                    expression: "address2",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "mb-3", attrs: { role: "group" } },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "d-letter-spacing",
                                    attrs: { for: "state" },
                                  },
                                  [_vm._v("State:")]
                                ),
                                _c("v-select", {
                                  attrs: {
                                    "append-to-body": "",
                                    "calculate-position": _vm.withPopper,
                                    label: "text",
                                    disabled: true,
                                    options: _vm.stateoptions,
                                    clearable: false,
                                    reduce: (stateoptions) =>
                                      stateoptions.value,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "open-indicator",
                                      fn: function ({ attributes }) {
                                        return [
                                          _c(
                                            "b-icon",
                                            _vm._b(
                                              {
                                                attrs: { icon: "chevron-down" },
                                              },
                                              "b-icon",
                                              attributes,
                                              false
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.state,
                                    callback: function ($$v) {
                                      _vm.state = $$v
                                    },
                                    expression: "state",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                            _c(
                              "div",
                              { staticClass: "mb-3", attrs: { role: "group" } },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "d-letter-spacing",
                                    attrs: { for: "city" },
                                  },
                                  [_vm._v("City:")]
                                ),
                                _c("b-form-input", {
                                  staticClass: "pl-0",
                                  attrs: {
                                    id: "city",
                                    type: "text",
                                    formatter: _vm.removeAccents,
                                  },
                                  model: {
                                    value: _vm.city,
                                    callback: function ($$v) {
                                      _vm.city = $$v
                                    },
                                    expression: "city",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                            _c(
                              "div",
                              { staticClass: "mb-3", attrs: { role: "group" } },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "d-letter-spacing",
                                    attrs: { for: "zipcode" },
                                  },
                                  [_vm._v("Zip Code:")]
                                ),
                                _c("b-form-input", {
                                  staticClass: "pl-0",
                                  attrs: { id: "zipcode", type: "text" },
                                  model: {
                                    value: _vm.zipCode,
                                    callback: function ($$v) {
                                      _vm.zipCode = $$v
                                    },
                                    expression: "zipCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-3 mb-3" },
                [
                  _c("b-col", { attrs: { cols: "12", md: "6" } }),
                  _c(
                    "b-col",
                    { attrs: { cols: "6", md: "3" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            pill: "",
                            block: "",
                            variant: "outline-info",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.resetFields("shipping")
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6", md: "3" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { pill: "", block: "", variant: "info" },
                          on: { click: _vm.updateShipping },
                        },
                        [_vm._v("Update")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("p", { staticClass: "text-center pt-3" }, [
                _c("i", [
                  _vm._v(
                    " If you wish to update your shipping state, kindly contact Winona's Patient Care Team to have the state updated. "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editBillingInfo,
                  expression: "editBillingInfo",
                },
              ],
            },
            [
              _c("h2", { staticClass: "mb-6 bold text-primary" }, [
                _vm._v("Billing Address"),
              ]),
              _c(
                "b-alert",
                { attrs: { show: _vm.alert.show, variant: _vm.alert.type } },
                [_vm._v(_vm._s(_vm.alert.message))]
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "mb-3", attrs: { role: "group" } },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "d-letter-spacing",
                                    attrs: { for: "billingAddress1" },
                                  },
                                  [_vm._v("Street Address:")]
                                ),
                                _c("vue-google-autocomplete", {
                                  ref: "streetAddressBilling",
                                  staticClass: "fs-mask onboarding-input",
                                  attrs: {
                                    id: "map2",
                                    classname: "form-control",
                                    placeholder: " ",
                                    country: "us",
                                  },
                                  on: {
                                    placechanged: _vm.getBillingAddressData,
                                    inputChange:
                                      _vm.onStreetAddressBillingInputChange,
                                    change: _vm.onStreetAddressBillingChange,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "mb-3", attrs: { role: "group" } },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "d-letter-spacing",
                                    attrs: { for: "billingAddress2" },
                                  },
                                  [_vm._v("Apartment/Suite:")]
                                ),
                                _c("b-form-input", {
                                  staticClass: "pl-0",
                                  attrs: {
                                    id: "billingAddress2",
                                    type: "text",
                                    formatter: _vm.removeAccents,
                                  },
                                  model: {
                                    value: _vm.billingAddress2,
                                    callback: function ($$v) {
                                      _vm.billingAddress2 = $$v
                                    },
                                    expression: "billingAddress2",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "mb-3", attrs: { role: "group" } },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "d-letter-spacing",
                                    attrs: { for: "billingState" },
                                  },
                                  [_vm._v("State:")]
                                ),
                                _c("v-select", {
                                  attrs: {
                                    "append-to-body": "",
                                    "calculate-position": _vm.withPopper,
                                    label: "text",
                                    options: _vm.stateoptions,
                                    clearable: false,
                                    reduce: (stateoptions) =>
                                      stateoptions.value,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "open-indicator",
                                      fn: function ({ attributes }) {
                                        return [
                                          _c(
                                            "b-icon",
                                            _vm._b(
                                              {
                                                attrs: { icon: "chevron-down" },
                                              },
                                              "b-icon",
                                              attributes,
                                              false
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.billingState,
                                    callback: function ($$v) {
                                      _vm.billingState = $$v
                                    },
                                    expression: "billingState",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                            _c(
                              "div",
                              { staticClass: "mb-3", attrs: { role: "group" } },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "d-letter-spacing",
                                    attrs: { for: "billingCity" },
                                  },
                                  [_vm._v("City:")]
                                ),
                                _c("b-form-input", {
                                  staticClass: "pl-0",
                                  attrs: {
                                    id: "billingCity",
                                    type: "text",
                                    formatter: _vm.removeAccents,
                                  },
                                  model: {
                                    value: _vm.billingCity,
                                    callback: function ($$v) {
                                      _vm.billingCity = $$v
                                    },
                                    expression: "billingCity",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                            _c(
                              "div",
                              { staticClass: "mb-3", attrs: { role: "group" } },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "d-letter-spacing",
                                    attrs: { for: "billingZipCode" },
                                  },
                                  [_vm._v("Zip Code:")]
                                ),
                                _c("b-form-input", {
                                  staticClass: "pl-0",
                                  attrs: { id: "billingZipCode", type: "text" },
                                  model: {
                                    value: _vm.billingZipCode,
                                    callback: function ($$v) {
                                      _vm.billingZipCode = $$v
                                    },
                                    expression: "billingZipCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-3 mb-3" },
                [
                  _c("b-col", { attrs: { cols: "12", md: "6" } }),
                  _c(
                    "b-col",
                    { attrs: { cols: "6", md: "3" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            pill: "",
                            block: "",
                            variant: "outline-info",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.resetFields("billing")
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6", md: "3" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { pill: "", block: "", variant: "info" },
                          on: { click: _vm.updateBillingInfo },
                        },
                        [_vm._v("Update")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.editCreditCard
            ? [
                _c(
                  "div",
                  { staticClass: "mb-6 d-flex justify-content-between" },
                  [
                    _c("div", [
                      _c("h2", { staticClass: "mb-0 bold text-primary" }, [
                        _vm._v("Billing Details"),
                      ]),
                      _c(
                        "small",
                        { staticClass: "text-muted" },
                        [
                          _c("b-icon", { attrs: { icon: "lock-fill" } }),
                          _vm._v(" 256-BIT TLS SECURITY "),
                        ],
                        1
                      ),
                    ]),
                    _c("div", [
                      _c("img", {
                        staticClass: "mr-2",
                        attrs: {
                          src: require("@/assets/images/payment-methods/visa.svg"),
                          height: "20",
                          alt: "Visa",
                        },
                      }),
                      _c("img", {
                        staticClass: "mr-2",
                        attrs: {
                          src: require("@/assets/images/payment-methods/mastercard.svg"),
                          height: "20",
                          alt: "Mastercard",
                        },
                      }),
                      _c("img", {
                        staticClass: "mr-2",
                        attrs: {
                          src: require("@/assets/images/payment-methods/discover.svg"),
                          height: "20",
                          alt: "Discover",
                        },
                      }),
                      _c("img", {
                        staticClass: "mr-2",
                        attrs: {
                          src: require("@/assets/images/payment-methods/amex.svg"),
                          height: "20",
                          alt: "American Express",
                        },
                      }),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/payment-methods/jcb.svg"),
                          height: "20",
                          alt: "JCB",
                        },
                      }),
                    ]),
                  ]
                ),
                _c(
                  "b-alert",
                  { attrs: { show: _vm.alert.show, variant: _vm.alert.type } },
                  [_vm._v(_vm._s(_vm.alert.message))]
                ),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { cols: "12" } }, [
                      _c(
                        "p",
                        {
                          staticClass: "text-primary",
                          staticStyle: {
                            "letter-spacing": "0px",
                            "margin-bottom": "0.5rem",
                          },
                        },
                        [_vm._v(" Credit or debit card ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c("div", {
                            ref: "card",
                            staticStyle: {
                              "background-color": "#f8fafc",
                              padding: "0.5rem",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-3 mb-3" },
                  [
                    _c("b-col", { attrs: { cols: "12", md: "6" } }),
                    _c(
                      "b-col",
                      { attrs: { cols: "6", md: "3" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              pill: "",
                              block: "",
                              variant: "outline-info",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.resetFields("card")
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "6", md: "3" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              pill: "",
                              block: "",
                              variant: "info",
                              disabled: _vm.disableCardUpdate,
                            },
                            on: { click: _vm.updateCard },
                          },
                          [_vm._v("Save")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.editSecurity
            ? [
                _c("h2", { staticClass: "mb-6 bold text-primary" }, [
                  _vm._v("Security Settings"),
                ]),
                _c(
                  "b-alert",
                  { attrs: { show: _vm.alert.show, variant: _vm.alert.type } },
                  [_vm._v(_vm._s(_vm.alert.message))]
                ),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { cols: "12" } }, [
                      _c("p", { staticClass: "bold" }, [
                        _vm._v("Change Email"),
                      ]),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "email" },
                            },
                            [_vm._v("Current Email Address:")]
                          ),
                          _c("b-form-input", {
                            staticClass: "pl-0 fs-mask",
                            attrs: { id: "email", type: "text", readonly: "" },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "nemail" },
                            },
                            [_vm._v("New Email Address:")]
                          ),
                          _c("b-form-input", {
                            staticClass: "pl-0 fs-mask",
                            attrs: {
                              autocomplete: "off",
                              id: "nemail",
                              type: "text",
                            },
                            model: {
                              value: _vm.newEmail,
                              callback: function ($$v) {
                                _vm.newEmail = $$v
                              },
                              expression: "newEmail",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "password" },
                            },
                            [_vm._v("Current Password:")]
                          ),
                          _c("b-form-input", {
                            staticClass: "pl-0 fs-mask",
                            attrs: {
                              autocomplete: "off",
                              id: "password",
                              type: "password",
                            },
                            model: {
                              value: _vm.password,
                              callback: function ($$v) {
                                _vm.password = $$v
                              },
                              expression: "password",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-3 mb-3" },
                  [
                    _c("b-col", { attrs: { cols: "12", md: "6" } }),
                    _c(
                      "b-col",
                      { attrs: { cols: "6", md: "3" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              pill: "",
                              block: "",
                              variant: "outline-info",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.resetFields("security")
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "6", md: "3" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { pill: "", block: "", variant: "info" },
                            on: { click: _vm.updateSecurity },
                          },
                          [_vm._v("Update")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-4" },
                  [
                    _c("b-col", { attrs: { cols: "12" } }, [
                      _c("p", { staticClass: "bold" }, [
                        _vm._v("Change Password"),
                      ]),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "password" },
                            },
                            [_vm._v("Current Password:")]
                          ),
                          _c("b-form-input", {
                            staticClass: "pl-0 fs-mask",
                            attrs: { id: "currentpassword", type: "password" },
                            model: {
                              value: _vm.currentPassword,
                              callback: function ($$v) {
                                _vm.currentPassword = $$v
                              },
                              expression: "currentPassword",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "mb-3", attrs: { role: "group" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "d-letter-spacing",
                              attrs: { for: "cpassword" },
                            },
                            [_vm._v("New Password:")]
                          ),
                          _c("b-form-input", {
                            staticClass: "pl-0 fs-mask",
                            attrs: { id: "cpassword", type: "password" },
                            model: {
                              value: _vm.confirmPassword,
                              callback: function ($$v) {
                                _vm.confirmPassword = $$v
                              },
                              expression: "confirmPassword",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("b-col", { attrs: { cols: "12" } }, [
                      _c("p", [
                        _vm._v("Forgot your password? "),
                        _c("a", { attrs: { href: "#" } }, [_vm._v("Reset It")]),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-3 mb-4" },
                  [
                    _c("b-col", { attrs: { cols: "12", md: "6" } }),
                    _c(
                      "b-col",
                      { attrs: { cols: "6", md: "3" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              pill: "",
                              block: "",
                              variant: "outline-info",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.resetFields("security")
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "6", md: "3" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { pill: "", block: "", variant: "info" },
                            on: { click: _vm.updatePassword },
                          },
                          [_vm._v("Update")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.editPhoto
            ? [
                !_vm.isMobile
                  ? _c(
                      "b-row",
                      { staticClass: "mb-4" },
                      [
                        _c(
                          "b-col",
                          { staticClass: "mb-4", attrs: { cols: "12" } },
                          [
                            _c(
                              "b-row",
                              { attrs: { "align-v": "center" } },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "h2",
                                      { staticClass: "mb-6 bold text-primary" },
                                      [_vm._v("Identity Verification")]
                                    ),
                                    _c("p", [
                                      _vm._v(
                                        " In order to safely prescribe medications, we need a valid government-issued ID that matches the name and date of birth you entered while completing the patient questionnaire. "
                                      ),
                                    ]),
                                    _c(
                                      "b-alert",
                                      {
                                        attrs: {
                                          show: _vm.alert.show,
                                          variant: _vm.alert.type,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.alert.message) + " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        !_vm.isMobile
                          ? _c(
                              "b-col",
                              { attrs: { cols: "12", md: "6" } },
                              [
                                _c(
                                  "small",
                                  { staticClass: "text-muted mb-3" },
                                  [_vm._v("Face Photo")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "photo-holder fs-exclude" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "photo",
                                        style: {
                                          backgroundImage:
                                            _vm.isPhotoTaken || _vm.showPhoto
                                              ? ""
                                              : "url(" +
                                                require("@/assets/images/photo-bg.svg") +
                                                ")",
                                        },
                                      },
                                      [
                                        _vm.isPhotoTaken
                                          ? _c("img", {
                                              attrs: { src: _vm.picture },
                                            })
                                          : _vm.showPhoto
                                          ? _c("img", {
                                              staticStyle: {
                                                "object-fit": "cover",
                                              },
                                              attrs: { src: _vm.imagePreview },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "photo-btn",
                                            attrs: {
                                              variant: "link",
                                              block: "",
                                            },
                                            on: { click: _vm.showCamera },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticStyle: {
                                                  "font-size": "inherit",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.isPhotoTaken
                                                      ? "Retake"
                                                      : "Take Photo"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "photo-btn",
                                            attrs: {
                                              block: "",
                                              variant: "link",
                                              disabled: _vm.isProcessingPhoto,
                                            },
                                          },
                                          [
                                            _c("input", {
                                              ref: "photo",
                                              staticClass: "hide-file-input",
                                              attrs: {
                                                type: "file",
                                                id: "photo",
                                                accept:
                                                  _vm.ACCEPTED_FILE_PATTERNS,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.photoUpload()
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "mb-0",
                                                attrs: { for: "photo" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.isProcessingPhoto
                                                        ? "Loading..."
                                                        : "Choose Photo"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.isMobile
                          ? _c(
                              "b-col",
                              { attrs: { cols: "12", md: "6" } },
                              [
                                _c(
                                  "small",
                                  { staticClass: "text-muted mb-3" },
                                  [_vm._v("Identity Card")]
                                ),
                                _c("div", { staticClass: "photo-holder" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "photo fs-exclude",
                                      style: {
                                        backgroundImage: _vm.showId
                                          ? ""
                                          : "url(" +
                                            require("@/assets/images/id-bg.svg") +
                                            ")",
                                        "background-size": "contain",
                                        "background-size": "contain",
                                        "background-color": "transparent",
                                      },
                                    },
                                    [
                                      _vm.showId
                                        ? _c("img", {
                                            staticClass: "fs-exclude",
                                            staticStyle: {
                                              "object-fit": "contain",
                                            },
                                            attrs: { src: _vm.idPreview },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "photo-btn",
                                            attrs: {
                                              block: "",
                                              variant: "link",
                                              disabled: _vm.isProcessingId,
                                            },
                                          },
                                          [
                                            _c("input", {
                                              ref: "identification",
                                              staticClass: "hide-file-input",
                                              attrs: {
                                                type: "file",
                                                id: "identification",
                                                accept:
                                                  _vm.ACCEPTED_FILE_PATTERNS,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.idUpload()
                                                },
                                              },
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "mb-0",
                                                attrs: {
                                                  for: "identification",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.isProcessingId
                                                        ? "Loading..."
                                                        : _vm.showId
                                                        ? "Choose Again"
                                                        : "Change Photo"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c(
                      "b-row",
                      { staticClass: "mb-4", attrs: { "align-h": "center" } },
                      [
                        _c("DashboardIdentityVerification", {
                          staticClass: "fs-exclude",
                          attrs: {
                            personalPhoto: _vm.imagePreview,
                            idPhoto: _vm.idPreview,
                          },
                          on: { setPhotoMobile: _vm.setPhotoMobile },
                        }),
                      ],
                      1
                    ),
                _c(
                  "b-row",
                  { staticClass: "mt-3 mb-4" },
                  [
                    _c("b-col", { attrs: { cols: "12", md: "6" } }),
                    _c(
                      "b-col",
                      { attrs: { cols: "6", md: "3" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              pill: "",
                              block: "",
                              variant: "outline-info",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.resetFields("photo")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isAndroid11WebView ? "Back" : "Cancel"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    !_vm.isAndroid11WebView
                      ? _c(
                          "b-col",
                          { attrs: { cols: "6", md: "3" } },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  pill: "",
                                  block: "",
                                  variant: "info",
                                  disabled: _vm.uploadingPhoto,
                                },
                                on: { click: _vm.updatePhoto },
                              },
                              [
                                _vm.uploadingPhoto
                                  ? _c("b-spinner", { attrs: { small: "" } })
                                  : _c("span", [_vm._v("Save")]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "modal",
        {
          staticClass: "fs-mask",
          attrs: {
            id: "takePhoto",
            size: "md",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
          },
        },
        [
          _c("video", {
            ref: "camera",
            attrs: { width: "100%", height: "325", autoplay: "" },
          }),
          _c("canvas", {
            ref: "canvas",
            staticClass: "photo-taken",
            attrs: { width: 325, height: 250 },
          }),
          _c(
            "b-button",
            {
              staticStyle: {
                margin: "15px auto",
                display: "block",
                "min-width": "200px",
              },
              attrs: { variant: "outline-info" },
              on: { click: _vm.takePhoto },
            },
            [_vm._v(" Capture ")]
          ),
        ],
        1
      ),
      _c(
        "modal",
        {
          ref: "modal",
          attrs: {
            id: "cancelAccount",
            size: "md",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-6" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "icon-logo" }),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-card" },
            [
              _c("h2", { staticClass: "mb-3 heading text-primary" }, [
                _vm._v(" Are you sure you want to delete your account? "),
              ]),
              _c("p", { staticClass: "mb-3" }, [
                _vm._v(
                  " Et dolor, ut sed facilisi ornare justo amet. Sed at lorem sed dignissim eros. Est morbi sit laoreet accumsan neque, non urna amet dolor. Quisque vitae id egestas vel. Nunc neque condi. "
                ),
              ]),
              _c(
                "b-row",
                {
                  staticClass: "text-center",
                  attrs: { "align-h": "center", "no-gutters": "" },
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            pill: "",
                            block: "",
                            variant: "outline-info",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toggleModal("cancelAccount")
                            },
                          },
                        },
                        [_vm._v("Confirm")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { pill: "", block: "", variant: "info" },
                          on: {
                            click: function ($event) {
                              return _vm.$bvModal.hide("cancelAccount")
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("loading", {
        attrs: {
          active: _vm.uploadingPhoto,
          "can-cancel": false,
          color: "#1a6a72",
        },
        on: {
          "update:active": function ($event) {
            _vm.uploadingPhoto = $event
          },
        },
      }),
      _c("loading", {
        attrs: {
          active: _vm.showLoader,
          "can-cancel": false,
          color: "#1a6a72",
        },
        on: {
          "update:active": function ($event) {
            _vm.showLoader = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }