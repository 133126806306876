<template>
  <div>
    <small class="text-muted mb-3">Face Photo</small>
    <MobileUpload
      key="mobileUploadPersonal"
      backgroundType="personal"
      :dashboard="true"
      @submitAnswerDashboard="setPhoto('personal', $event)"
      :existingPhoto="personalForComponent"
    />
    <small class="text-muted mb-3">ID Photo</small>
    <MobileUpload
      key="mobileUploadId"
      backgroundType="id"
      :dashboard="true"
      :existingPhoto="idForComponent"
      @submitAnswerDashboard="setPhoto('id', $event)"
    />
  </div>
</template>

<script>
import MobileUpload from "@/components/partials/MobileUpload";

export default {
  components: {
    MobileUpload,
  },
  props: {
    personalPhoto: {
      type: String,
      default: "",
    },
    idPhoto: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      alert: {
        show: false,
        message: "",
      },
      personalForComponent: "",
      idForComponent: "",
    };
  },
  methods: {
    setPhoto(type, event) {
      this.$emit("setPhotoMobile", type, event);
    },
  },
  mounted() {
    if (this.personalPhoto) {
      this.personalForComponent = this.personalPhoto;
    }
    if (this.idPhoto) {
      this.idForComponent = this.idPhoto;
    }
  },
};
</script>
